import React, { useState, useMemo, useEffect, lazy } from 'react';
import { Breadcrumb, Nav, Spinner, Tab } from 'react-bootstrap';
import Countdown from 'react-countdown';
// import DataTable from "../../../../components/DataTable";
import 'react-table-v6/react-table.css';
import { client } from '../../../../services/apiService';
import { useSelector } from 'react-redux';
import * as AuthSelector from '../../../../store/auth/selectors';
import { useNavigate } from 'react-router-dom';
const CustomDataTable = lazy(() => import('../../../CustomDataTable'));
import moment from 'moment';
import validUrl from 'valid-url';
const PaginationBar = lazy(
    () => import('../../../../components/partials/PaginationBar')
);
import _ from 'lodash';
import { ErrorNotify, imagePath, toolTipHOC, urlPath } from '../../../../CommonLogic';

const PerformAuditList = ({ showFor }) => {
    const [data, setData] = useState([]);
    const [totalAuditCount, setTotalAuditCount] = useState(0);
    const [totalRebuttalCount, setTotalRebuttalCount] = useState(0);
    const [columns, setColumns] = useState([]);
    const [rebuttalData, setRebuttalData] = useState([]);
    const [rebuttalCols, setRebuttalCols] = useState([]);

    const [auditPage, setAuditPage] = useState(1);
    const [rebuttalPage, setRebuttalPage] = useState(1);

    const [auditPageCount, setAuditPageCount] = useState(1);
    const [rebuttalPageCount, setRebuttalPageCount] = useState(1);

    const [auditPageSize, setAuditPageSize] = useState(10);
    const [rebuttalPageSize, setRebuttalPageSize] = useState(10);

    document.title = `My Allocation | Omind`;
    const [isLoading, setLoading] = useState(false);
    const navigate = useNavigate();
    const orgStrId = useSelector(AuthSelector.orgStrId);

    const getMyAudits = async () => {
        const response = await client.get(
            `/my-audits?page=${auditPage}&limit=${auditPageSize}&offset=${new Date().getTimezoneOffset()}`
        );
        if (response?.meta?.pagination?.pageCount) {
            setAuditPageCount(response?.meta?.pagination?.pageCount);
            setTotalAuditCount(response?.meta?.pagination?.totalCount);
        }
        const timeZoneOffset = new Date().getTimezoneOffset() * 60 * 1000;
        const tempData = response?.data?.map((row, idx) => {
            const timeFormatted = new Date(row.createdAt);
            const endTime = new Date(
                timeFormatted.getTime() +
                    Number((row.Audit_Form?.Audit_Response_Time || 3) + '') *
                        24 *
                        60 *
                        60 *
                        1000 -
                    timeZoneOffset
            );

            return {
                sl_no: {
                    value:
                        auditPage && auditPageSize
                            ? (auditPage - 1) * auditPageSize + (idx + 1)
                            : idx + 1,
                },
                ticket_id: {
                    value: [
                        'Accepted',
                        'Rebuttal Rejected',
                        'Audit Edited',
                    ].includes(row.Audit_Status_Current) ? (
                        <a
                            href='javascript:void(0)'
                            onClick={e => {
                                navigate(
                                    `/o/${orgStrId}/view-audit/${row.Ticket_Id}`,
                                    {
                                        state: {
                                            auditData: row,
                                        },
                                    }
                                );
                            }}>
                            {' '}
                            {row.Ticket_Id}
                        </a>
                    ) : row?.Ticket_Id ? (
                        row.Ticket_Id
                    ) : (
                        '-'
                    ),
                },
                obs: {
                    value: {
                        label: getSheetName(_.get(row, "CRM_Import.Department.Display_Name"," ")),
                        value: _.get(row, "CRM_Import.Department.id"," "),
                    },
                },
                auditDate: {
                    value: moment(row.createdAt).format('DD-MMM-YYYY'),
                },
                score: {
                    value: row?.Overall_Score
                        ? `${row?.Overall_Score?.toFixed(1)}%`
                        : '0%',
                },
                remaining: {
                    isComponent: true,
                    value:
                        row.Audit_Status_Current == 'Accepted' ||
                            row.Audit_Status_Current == 'Audit Reviewed' ||
                            row.Audit_Status_Current == 'Rebuttal Rejected' ||
                            row.Audit_Status_Current == 'Audit Edited' ? (
                            'N/A'
                        ) : (
                            <Countdown date={endTime.getTime()} />
                        ),
                },
                action: {
                    value: actionTypeAgent(row, idx, row.id),
                    isComponent: true,
                },
            };
        });
        setData(tempData);
    };
    const getToBeAudited = async () => {
        const { data = [], meta } = await client.get(
            `/my-to-be-audited?page=${auditPage}&limit=${auditPageSize}`
        );
        if (meta?.pagination?.pageCount) {
            setAuditPageCount(meta.pagination?.pageCount);
            setTotalAuditCount(meta.pagination?.totalCount);
        }

        const tempData = data?.map((row, idx) => {
            return {
                sl_no: {
                    value:
                        auditPage && auditPageSize
                            ? (auditPage - 1) * auditPageSize + (idx + 1)
                            : idx + 1,
                },
                ticket_id: {
                    value: row.Audit_Status == 'Done' ?
                        (
                            <a
                                href='javascript:void(0)'
                                className='decoration_line'
                                onClick={e => {
                                    if (row.Audit_Status == "Done") {
                                        navigate(
                                            `/o/${orgStrId}/view-audit-qa/${row.Ticket_Id}`,
                                            {
                                                state: {
                                                    auditData: row,
                                                },
                                            }
                                        );
                                    }

                                }}>
                                {' '}
                                {row?.Ticket_Id ? row.Ticket_Id : '-'}
                            </a>
                        )
                        :
                        row?.Ticket_Id ?? '--',
                },
                Agent_Name: {
                    value: row?.Agent?.first_name + ' ' + row?.Agent?.last_name,
                },
                obs: {
                    value: {
                        label: getSheetName(_.get(row, "Department.Display_Name", " ")),
                        value: _.get(row, "Department.id"," "),
                    },
                },
                audioUrl: {
                    value: (
                        <div>
                            <div style={{ display: 'flex' }}>
                                <div style={{width:'90%'}}>
                                    <input
                                        id={'inputAudio' + idx}
                                        defaultValue={row.Media_Url ? "Url saved successfully." : ""}
                                        style={{ padding: '4px 8px', height: 'auto' }}
                                        type='text'
                                        className='form-control audio-url'
                                        placeholder='Paste URL of Audio File'
                                        disabled={
                                            row.Audit_Status == 'Done' ||
                                            (!_.isEmpty(row.Media_Url) &&
                                                !getStatusByUpdationTime(row.updatedAt))
                                        }
                                    />

                                    <div className='invalid-feedback'>
                                        Please enter a valid Audio URL
                                    </div>

                                </div>
                                {row.Department?.Audio_Upload && !row.Media_Url &&
                                    <div style={{width:'30px', paddingTop: '2px'}}>
                                        <label htmlFor={`uploadInput${idx}`} style={{ cursor: 'pointer' }}>
                                            {toolTipHOC(<img src={imagePath('/images/upload_icon.svg')} className={`uploadaudio uploadaudio${idx}`} />, 'Upload audio file')}
                                            <div className={`uploadaudiospinner${idx} uploadaudiospinner`} style={{ display: 'none' }}>
                                                <Spinner></Spinner>
                                            </div>
                                        </label>
                                        <input
                                            type='file'
                                            id={`uploadInput${idx}`}
                                            accept="audio/*"
                                            style={{ display: 'none' }}
                                            onChange={(e) => handleAudioUpload(e, row, idx)}
                                        />
                                    </div>
                                }
                            </div>
                        </div>
                    ),
                    isComponent: true,
                },
                action: {
                    value: actionType(row, idx, row.Media_Url),
                    isComponent: true,
                },
            };
        });
        setData(tempData);
    };
    const getRebuttals = async () => {
        let url = `/my-rebuttles?page=${rebuttalPage}&limit=${rebuttalPageSize}&offset=${new Date().getTimezoneOffset()}`;
        if (showFor == 'Agent') {
            url += '&type=agent';
        }
        const rebuttals = await client.get(url);
        if (rebuttals?.meta?.pagination?.pageCount) {
            setRebuttalPageCount(rebuttals?.meta?.pagination?.pageCount);
            setTotalRebuttalCount(rebuttals?.meta?.pagination?.totalCount);
        }
        const tempData = rebuttals?.data?.map((row, idx) => {
            let actor = row.Agent_Info;
            if (showFor == 'Agent') {
                actor = row.Auditor_Info;
            }
            return {
                sl_no: {
                    value:
                        rebuttalPage && rebuttalPageSize
                            ? (rebuttalPage - 1) * rebuttalPageSize + (idx + 1)
                            : idx + 1,
                },
                ticket_id: {
                    value: (
                        <a
                            href='javascript:void(0)'
                            className='decoration_line'
                            onClick={e => {
                                navigate(
                                    `/o/${orgStrId}/${showFor == 'Agent' || row.Audit_Status_Current == 'Rebuttal Rejected' ? 'view-audit' : 'edit-audit'}/${row.Ticket_Id}`,
                                    {
                                        state: {
                                            auditData: row,
                                        },
                                    }
                                );
                            }}>
                            {' '}
                            {row?.Ticket_Id ? row.Ticket_Id : '-'}
                        </a>
                    ),
                },
                auditDate: {
                    value: moment(row.createdAt).format('DD MMM, YYYY'),
                },
                actor: {
                    value: actor.first_name
                        ? actor.first_name +
                        (' ' + (row.last_name || '')).trim()
                        : actor.email.split('@')[0].replace(/\./gi, ' '),
                },
                score: {
                    value: row?.Overall_Score
                        ? `${row?.Overall_Score?.toFixed(1)}%`
                        : '0%',
                },
                comment: {
                    value:
                        row.Audit_Status_Current == 'Rebuttal Rejected' ? (
                            'Rebuttal has been rejected by the QA'
                        ) : row?.Audit_Feedbacks?.length ? (
                            <i>
                                {
                                    row.Audit_Feedbacks[
                                        row?.Audit_Feedbacks.length - 1
                                    ].Comment
                                }
                            </i>
                        ) : (
                            <i>no comments</i>
                        ),
                },
            };
        });
        setRebuttalData(tempData);
    };

    useMemo(async () => {
        setLoading(true);
        if (showFor == 'Agent') {
            setColumns([
                {
                    title: 'Sl. NO',
                    style: {
                        width: '5%',
                    },
                },
                { title: 'Ticket/Call ID' },
                { title: 'OBS' },
                { title: 'Audit Date' },
                { title: 'Score' },
                { title: 'Remaining Time' },
                { title: 'Action' },
            ]);
            setRebuttalCols([
                {
                    title: 'Sl. NO',
                    style: {
                        width: '5%',
                    },
                },
                { title: 'Ticket/Call ID' },
                { title: 'Date' },
                { title: 'QA Name' },
                { title: 'Score' },
                { title: 'Comment' },
            ]);
            setAuditPageCount("")
            setTotalAuditCount("")
            getMyAudits();
        } else {
            setColumns([
                {
                    title: 'Sl. NO',
                    style: {
                        width: '5%',
                    },
                },
                { title: 'Ticket/Call ID' },
                { title: 'Agent Name' },
                { title: 'OBS' },
                { title: 'Audio File' },
                { title: 'Action' },
            ]);
            setRebuttalCols([
                {
                    title: 'Sl. NO',
                    style: {
                        width: '5%',
                    },
                },
                { title: 'Ticket/Call ID' },
                { title: 'Date' },
                { title: 'Agent Name' },
                { title: 'Score' },
                { title: 'Comment' },
            ]);
            getToBeAudited();
        }
        setTotalRebuttalCount("")
        setRebuttalPageCount("")
        getRebuttals();
        setLoading(false);
    }, [showFor, orgStrId]);

    useEffect(() => {
        refreshAudits();
    }, [auditPage, auditPageSize]);

    useEffect(() => {
        refreshRebuttals();
    }, [rebuttalPage, rebuttalPageSize]);

    const refreshRebuttals = async () => {
        setLoading(true);
        await getRebuttals();
        setLoading(false);
    };

    const refreshAudits = async () => {
        setLoading(true);
        if (showFor == 'Agent') {
            await getMyAudits();
        } else {
            await getToBeAudited();
        }
        setLoading(false);
    };

    const getSheetName = (title, isToolTip = false) => {
        title = title.replace(/- Autogenerated/gi, '');
        if (isToolTip) {
            return title;
        }
        const temp = title.split(' / ');
        if (temp?.length > 2) {
            return (
                '... / ' + temp[temp.length - 2] + ' / ' + temp[temp.length - 1]
            );
        } else {
            return title;
        }
    };
    const actionType = (val, index, media_Url) => {
        let buttonComponent;

        switch (val.Audit_Status) {
            case 'Done': {
                buttonComponent = (
                    <a className='audit_btn complete_btn' href=''>
                        Completed
                    </a>
                );

                break;
            }

            case 'Pending':
            case 'In-Progress': {
                buttonComponent = (
                    <a
                        className={`audit_btn ${getStatusByUpdationTime(val.updatedAt) ? 'success_btn' : val.Audit_Status == 'In-Progress' ? 'yellow_btn' : 'success_btn'}`}
                        onClick={e => {
                            const audioPath = document.getElementById(
                                'inputAudio' + index
                            );

                            if(audioPath?.value?.trim()=="Url saved successfully."){
                                handleAudioUpload(e, val, index, media_Url)
                                return;
                            }
                            const audioUrl = audioPath?.value?.trim();
                            const isValid = checkValidUrl(audioUrl);
                            try {
                                new URL(audioUrl);
                            } catch (ee) {
                                audioPath.classList.add('invalid');
                                e.preventDefault();
                                audioPath.value = '';
                                return;
                            }

                            if (!audioUrl || !isValid) {
                                audioPath.classList.add('invalid');
                                e.preventDefault();
                                audioPath.value = '';
                                return;
                            }
                            audioPath.classList.remove('invalid');
                            handleAudioUpload(e, val, index,audioUrl)
                        }}
                    // href="#"
                    >
                        {getStatusByUpdationTime(val.updatedAt)
                            ? 'Start'
                            : val.Audit_Status == 'In-Progress'
                                ? 'Resume'
                                : 'Start'}
                    </a>
                );
                break;
            }

            default: {
                buttonComponent = (
                    <a className='audit_btn yellow_btn' href=''>
                        Start
                    </a>
                );
            }
        }
        return buttonComponent;
    };
    const actionTypeAgent = (val, index, id) => {
        let buttonComponent;
        switch (val.Audit_Status_Current) {
            case 'Audit Done':
                buttonComponent = (
                    <a
                        className='audit_btn audit_report- bordered_btn btn '
                        onClick={e => {
                            navigate(
                                `/o/${orgStrId}/view-audit/${val.Ticket_Id}`,
                                {
                                    state: {
                                        auditData: val,
                                    },
                                }
                            );
                        }}>
                        View Report
                    </a>
                );
                break;
            case 'Rebuttal Rejected':
                buttonComponent = (
                    <a className='audit_btn complete_btn' href=''>
                        {'Audit Reviewed'}
                    </a>
                );
                break;
            default: {
                buttonComponent = (
                    <a className='audit_btn complete_btn' href=''>
                        {val.Audit_Status_Current}
                    </a>
                );
            }
        }
        return buttonComponent;
    };

    const handleAudioUpload = async (e, row, idx, audioFile = false) => {
        let file = "";
        if (!audioFile) {
            file = e?.target?.files[0]
        }
        let fileTypeCategory
        const supportedFileType = ['audio', 'video'];
        if (file) {
            const fileTypeParts = file?.type?.split('/');
            fileTypeCategory = fileTypeParts[0];
        }
        if (file && !supportedFileType.includes(fileTypeCategory) && !audioFile) {
            ErrorNotify('Please upload valid audio file')
            return;
        }
        let uploadAudioBtn = "";
        let spinner = "";
        if (row.Department.Audio_Upload) {
            uploadAudioBtn = document.querySelector(`.uploadaudio${idx}`);
            spinner = document.querySelector(`.uploadaudiospinner${idx}`);
            if (uploadAudioBtn) uploadAudioBtn.style.display = "none";
            if (spinner) spinner.style.display = "block";
        }

        if (!audioFile && row.Department.Audio_Upload) {
            const formData = new FormData();
            formData.append('files', file);
            formData.append('refId', row.id);
            formData.append('ref', 'api::crm-import.crm-import');
            formData.append('field', 'Audio_File');
            try {
                const { data: { data: [{ url }] } } = await client.post('/upload', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
                audioFile = url
            } catch (error) {
                ErrorNotify('File upload failed. Please try again later!');
                if (spinner) spinner.style.display = "none";
                if (uploadAudioBtn) uploadAudioBtn.style.display = "block";
                return;
            }
        }

        try {
            const response = await client.put('/upload-url/' + row.id, { data: { Media_Url: audioFile, departmentId: row.Department.id } });
            if (response.success) {
                navigate(
                    `/o/${orgStrId}/perform-audit/${row.Ticket_Id}`,
                    {
                        state: {
                            audioUrl: audioFile,
                            department: row.Department,
                            ticket_id: row.Ticket_Id,
                            crm_import_id: row.id,
                            agent_id: row.Agent?.id,
                            transcription: response?.data?.data?.Transcription,
                            conversation_summary:response?.data?.data?.Conversation_Summary,
                            conversation_sentiment:response?.data?.data?.Sentiment,
                            agent_sentiment:response?.data?.data?.Agent_Sentiment,
                            customer_sentiment:response?.data?.data?.Customer_Sentiment
                        },
                    }
                );
            }
            else if (response?.error && response?.error?.response?.data?.data?.error?.message == "Error in Deepgram processing") {
                ErrorNotify('Error while transcribing audio')
                if (uploadAudioBtn) uploadAudioBtn.style.display = "block";
            }
            else {
                ErrorNotify('Something went wrong. Please try again later!');
                if (uploadAudioBtn) uploadAudioBtn.style.display = "block";
            }
        } catch (error) {
            ErrorNotify('Something went wrong. Please try again later!');
        } finally {
            if (spinner) spinner.style.display = "none";
        }
    };
    

    return (
        <>
            <div className='page-wrapper'>
                <div className='content pb-0'>
                    <div className='row'>
                        <div className='col-sm-12 content_wrap_style'>
                            <div className='content_wrap_comman'>
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <div className='breadcrumb_box'>
                                            <Breadcrumb>
                                                <Breadcrumb.Item
                                                    as='li'
                                                    href={urlPath('/')}>
                                                    <img
                                                        src={imagePath(
                                                            '/images/home_icon_pagig.svg'
                                                        )}
                                                    />
                                               </Breadcrumb.Item>
                                                <Breadcrumb.Item
                                                    as='li'
                                                    active
                                                    aria-current='page'>
                                                    {showFor == 'Agent'
                                                        ? 'View My Audits'
                                                        : 'Perform Audit List'}
                                                </Breadcrumb.Item>
                                            </Breadcrumb>
                                        </div>

                                        <h3 className='page_title'>
                                            {'Audit Sheet List'}
                                        </h3>
                                    </div>
                                </div>
                                <div className='audit_list_view mt-3'>
                                    <div className='tab_view'>
                                        <Tab.Container
                                            defaultActiveKey='first'
                                            key={'REB' + (showFor || 'QA')}>
                                            <Nav
                                                variant='pills'
                                                data-toggle='slider-tab'
                                                role='tablist'>
                                                <Nav.Item role='presentation'>
                                                    <Nav.Link
                                                        eventKey='first'
                                                        variant='  d-flex align-items-center'
                                                        data-bs-toggle='tab'
                                                        data-bs-target='#pills-list_audit'
                                                        type='button'
                                                        role='tab'
                                                        aria-controls='home'
                                                        aria-selected='true'>
                                                        List of Audit{' '}
                                                        {totalAuditCount ? (
                                                            <span className='tab_nu_count soft_green'>
                                                                {
                                                                    totalAuditCount
                                                                }
                                                            </span>
                                                        ) : null}
                                                    </Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item role='presentation'>
                                                    <Nav.Link
                                                        eventKey='second'
                                                        variant=' d-flex align-items-center'
                                                        data-bs-toggle='tab'
                                                        data-bs-target='#pills-rebuttal'
                                                        type='button'
                                                        role='tab'
                                                        aria-controls='profile'
                                                        aria-selected='false'>
                                                        Rebuttal Raised{' '}
                                                        {totalRebuttalCount ? (
                                                            <span className='tab_nu_count soft_green'>
                                                                {
                                                                    totalRebuttalCount
                                                                }
                                                            </span>
                                                        ) : null}
                                                    </Nav.Link>
                                                </Nav.Item>
                                            </Nav>
                                            <Tab.Content className='mt-4'>
                                                <Tab.Pane
                                                    eventKey='first'
                                                    variant=' fade show active'
                                                    id='pills-list_audit'
                                                    role='tabpanel'
                                                    aria-labelledby='pills-list_audit-tab1'>
                                                    <div className='table_style_comman audit_list_table'>
                                                        <div className='table-responsive'>
                                                            <CustomDataTable
                                                                isLoading={
                                                                    isLoading
                                                                }
                                                                options={{
                                                                    columns,
                                                                    data,
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <PaginationBar
                                                        onPageSizeChange={
                                                            setAuditPageSize
                                                        }
                                                        setCurrentPage={
                                                            setAuditPage
                                                        }
                                                        key='audits'
                                                        nPages={auditPageCount}
                                                        currentPage={auditPage}
                                                        base={`/o/${orgStrId}/${showFor == 'Agent' ? 'view-my-audit/list' : 'perform-audit/list'}?tab=1&`}
                                                    />
                                                </Tab.Pane>
                                                
                                                <Tab.Pane
                                                    eventKey='second'
                                                    className='fade'
                                                    id='pills-rebuttal'
                                                    role='tabpanel'
                                                    aria-labelledby='pills-rebuttal-tab1'>
                                                    <div className='table_style_comman audit_list_table pills-rebuttal-tab1'>
                                                        <div className='table-responsive'>
                                                            <CustomDataTable
                                                                isLoading={
                                                                    isLoading
                                                                }
                                                                options={{
                                                                    columns:
                                                                        rebuttalCols,
                                                                    data: rebuttalData,
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <PaginationBar
                                                        onPageSizeChange={
                                                            setRebuttalPageSize
                                                        }
                                                        setCurrentPage={
                                                            setRebuttalPage
                                                        }
                                                        key='audits'
                                                        nPages={
                                                            rebuttalPageCount
                                                        }
                                                        currentPage={
                                                            rebuttalPage
                                                        }
                                                    />
                                                </Tab.Pane>
                                            </Tab.Content>
                                        </Tab.Container>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PerformAuditList;

function checkValidUrl(url) {
    const urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
    if (!urlRegex.test(url) || url.match(/(http|https)/g).length > 1) {
        return false;
    }
    if (validUrl.isUri(url)) {
        return true;
    }
    return false;
}

function getStatusByUpdationTime(time) {
    const updatedMoment = moment(time);
    const currentMoment = moment();
    const hoursDifference = currentMoment.diff(updatedMoment, 'hours');
    return hoursDifference > 24 ? true : false;
}
