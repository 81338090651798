export const RC_REBUTTAL = 'Rebuttal';
export const RC_ACTION_REBUTTAL = 'Rebuttal';
export const RC_REBUTTAL_REJECTED = 'Rebuttal Rejected';
export const RC_AUDIT_REVIEWED = 'Audit Reviewed';
export const RC_ACCEPT = 'Accept';
export const RC_ACCEPTED = 'Accepted';
export const RC_ACTION_ACCEPT = 'Accept';
export const RC_AUDIT_DONE = 'Audit Done';
export const RC_AUDIT_EDITED = 'Audit Edited';
export const RC_REJECT = 'Reject';
export const RC_ACTION_REJECT = 'Reject';
export const RC_AGENT = 'Agent';
export const NF_AGENT = 'Agent';
export const RC_ACTION_ALL = 'All';
export const RC_COMMENTS = 'Comments';
export const RC_ACTION_COMMENTS = 'Comments';
export const RC_NO_COMMENT = '--SYS-NO-COMMENT--';
export const RC_ACTION_COMMENT = 'comment';
export const RC_COMMENT = 'comment';
export const RC_HISTORY = 'History';
export const RC_ACTION_HISTORY = 'History';
export const RC_ACTION_EDITED = 'edited';
export const RC_ACTION_REBUTTAL_SMALL_CASE = 'rebuttal';
export const RC_ACTION_ACCEPTED = 'accepted';
export const RC_ACTION_REBUTTAL_REJECTED = 'rebuttal-rejected';
export const RC_EDIT = 'Edit';
export const NF_MARK_ALL = 'Mark All';
export const NF_API = 'api';
export const SM_PKT = 'process-knowledge-test'; // sidebar-menu.js
export const SM_NF = 'notice-feed';
export const SM_CREATE_TEST = 'create-test';
export const CT_QUESTION = 'question';
export const CT_MARKS = 'marks';
export const CT_CHOICE = 'choice';
export const CT_CORRECT_OPTION = 'correctOption';
export const CT_TIME_LIMIT_HOUR = 'timeLimitHour';
export const CT_TIME_LIMIT_MINUTE = 'timeLimitMinute';
export const CT_PASSING_MARKS = 'passingMarks';
export const CT_PUBLISH_PKT_SCREEN = 'publishPktScreen';
export const CT_ADD_QUESTION_SCREEN = 'addQuestionScreen';
export const PKT_UNASSIGNED = 'UNASSIGNED';
export const PKT_INPROGRESS = 'INPROGRESS';
export const PKT_ASSIGNED = 'ASSIGNED';
export const PKT_COMPLETED = 'COMPLETED';
export const PKT_AGENT_PENDING = 'Pending';
export const PKT_AGENT_NOT_ATTENDED = 'Not Attended';
export const PKT_AGENT_COMPLETED = 'Completed';
export const PA_AGENT = 'Agent';
export const PA_CUSTOMER = 'Customer';
export const permissionImages = {
    Dashboard: '/images/menu_icon_dashboard.svg',
    'Integration Setup': '/images/integration_setup_permission.svg',
    'Filter Setup': '/images/filter_setup_permission.svg',
    'Audit Sheet Setup': '/images/menu_icon_audit_sheet.svg',
    'Perform Audit': '/images/perform_audit_icon_permission.svg',
    'View Audits': '/images/view_my_audit_permission.svg',
    'Knowledge Management': '/images/knowledge_management_permission.svg',
    'Organization Setup': '/images/settings_icon_permission.svg',
    'User Onboarding': '/images/user_onboarding_permission.svg',
    'Quality Function Dashboard': '/images/qa-dashboard_permission.svg',
    Billing: '/images/billing_icon_permission.svg',
    'Work Allocation Dashboard': '/images/work_allocation_permission.svg',
    'Access Management': '/images/access_management_permission.svg',
    'User Management': '/images/user_management_permission.svg',
    'Metabase Analytics': '/images/metebase_analytics.svg',
    'Organisation Profile': '/images/organisation_profile_permission.svg',
};
export const countryList = [
    'Afghanistan',
    'Albania',
    'Algeria',
    'Andorra',
    'Angola',
    'Antigua and Barbuda',
    'Argentina',
    'Armenia',
    'Australia',
    'Austria',
    'Azerbaijan',
    'Bahamas',
    'Bahrain',
    'Bangladesh',
    'Barbados',
    'Belarus',
    'Belgium',
    'Belize',
    'Benin',
    'Bhutan',
    'Bolivia',
    'Bosnia and Herzegovina',
    'Botswana',
    'Brazil',
    'Brunei',
    'Bulgaria',
    'Burkina Faso',
    'Burundi',
    'Cabo Verde',
    'Cambodia',
    'Cameroon',
    'Canada',
    'Central African Republic',
    'Chad',
    'Chile',
    'China',
    'Colombia',
    'Comoros',
    'Congo',
    'Costa Rica',
    'Croatia',
    'Cuba',
    'Cyprus',
    'Czech Republic',
    'Denmark',
    'Djibouti',
    'Dominica',
    'Dominican Republic',
    'East Timor',
    'Ecuador',
    'Egypt',
    'El Salvador',
    'Equatorial Guinea',
    'Eritrea',
    'Estonia',
    'Eswatini',
    'Ethiopia',
    'Fiji',
    'Finland',
    'France',
    'Gabon',
    'Gambia',
    'Georgia',
    'Germany',
    'Ghana',
    'Greece',
    'Grenada',
    'Guatemala',
    'Guinea',
    'Guinea-Bissau',
    'Guyana',
    'Haiti',
    'Honduras',
    'Hungary',
    'Iceland',
    'India',
    'Indonesia',
    'Iran',
    'Iraq',
    'Ireland',
    'Israel',
    'Italy',
    'Jamaica',
    'Japan',
    'Jordan',
    'Kazakhstan',
    'Kenya',
    'Kiribati',
    'Korea, North',
    'Korea, South',
    'Kosovo',
    'Kuwait',
    'Kyrgyzstan',
    'Laos',
    'Latvia',
    'Lebanon',
    'Lesotho',
    'Liberia',
    'Libya',
    'Liechtenstein',
    'Lithuania',
    'Luxembourg',
    'Madagascar',
    'Malawi',
    'Malaysia',
    'Maldives',
    'Mali',
    'Malta',
    'Marshall Islands',
    'Mauritania',
    'Mauritius',
    'Mexico',
    'Micronesia',
    'Moldova',
    'Monaco',
    'Mongolia',
    'Montenegro',
    'Morocco',
    'Mozambique',
    'Myanmar',
    'Namibia',
    'Nauru',
    'Nepal',
    'Netherlands',
    'New Zealand',
    'Nicaragua',
    'Niger',
    'Nigeria',
    'North Macedonia',
    'Norway',
    'Oman',
    'Pakistan',
    'Palau',
    'Panama',
    'Papua New Guinea',
    'Paraguay',
    'Peru',
    'Philippines',
    'Poland',
    'Portugal',
    'Qatar',
    'Romania',
    'Russia',
    'Rwanda',
    'Saint Kitts and Nevis',
    'Saint Lucia',
    'Saint Vincent and the Grenadines',
    'Samoa',
    'San Marino',
    'Sao Tome and Principe',
    'Saudi Arabia',
    'Senegal',
    'Serbia',
    'Seychelles',
    'Sierra Leone',
    'Singapore',
    'Slovakia',
    'Slovenia',
    'Solomon Islands',
    'Somalia',
    'South Africa',
    'South Sudan',
    'Spain',
    'Sri Lanka',
    'Sudan',
    'Suriname',
    'Sweden',
    'Switzerland',
    'Syria',
    'Taiwan',
    'Tajikistan',
    'Tanzania',
    'Thailand',
    'Togo',
    'Tonga',
    'Trinidad and Tobago',
    'Tunisia',
    'Turkey',
    'Turkmenistan',
    'Tuvalu',
    'Uganda',
    'Ukraine',
    'United Arab Emirates',
    'United Kingdom',
    'United States',
    'Uruguay',
    'Uzbekistan',
    'Vanuatu',
    'Vatican City',
    'Venezuela',
    'Vietnam',
    'Yemen',
    'Zambia',
    'Zimbabwe',
];
export const kpiBaseRoot = "https://omind-computelayer-testserver.site/cci_analysis/analyze_call";
export const EXACT_MATCH_SEPARATOR = '^^SEPARATOR^^'
